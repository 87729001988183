<template>
  <div>
    <v-card flat>
      <v-card-text>
        <div v-if="!loading">
          <v-toolbar color="transparent" dense flat>
            <v-spacer></v-spacer>
            <v-chip>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    icon
                    v-on="on"
                    :loading="downloadingExport"
                    @click="downloadExport()"
                  >
                    <v-icon small>download</v-icon>
                  </v-btn>
                </template>
                Download Export
              </v-tooltip>
              <v-icon small color="grey">search</v-icon>
              <v-text-field
                placeholder="Search"
                class="mb-1"
                hide-details
                rounded
                clearable
                dense
                style="width: 300px"
                v-model="search"
                @change="searchKey++"
              ></v-text-field>
            </v-chip>
            <v-btn text @click="$emit('close')">X</v-btn>
          </v-toolbar>
          <v-card-text style="max-height: 75vh; overflow-y: auto" class="mt-0" :key="filterKey">
            <v-row justify="end">
              <v-col cols="12" sm="12" class="mt-0 pt-0" :key="filterKey">
                <v-list dense subheader>
                  <v-divider></v-divider>
                  <v-progress-linear
                    indeterminate
                    color="primary"
                    v-if="loading"
                  ></v-progress-linear>
                  <table :width="'100%'" class="table-fixed" >
                    <thead
                      style="
                        padding-top: 0px;
                        margin-top: 0px;
                        font-size: 12px;
                        z-index: 999999;
                        top: 0px;
                        bottom: 25px;
                      "
                    >
                      <th
                        class="text-center"
                        style="position: sticky"
                        v-for="(header, index) in headers"
                        :key="index"
                      >
                        <b style="font-size: 11px">{{ header.text }}</b> <br />
                        <v-btn
                          v-if="header.allowSort"
                          icon
                          x-small
                          @click="changeSortBy(header.value)"
                        >
                          <v-icon
                            v-if="sortBy != header.value"
                            small
                            color="grey"
                            >arrow_upward</v-icon
                          >
                          <v-icon v-else-if="sortDesc" small
                            >arrow_downward</v-icon
                          >
                          <v-icon v-else small>arrow_upward</v-icon>
                        </v-btn>
                        <v-badge
                          v-if="header.allowFilter"
                          :key="filterKey"
                          :content="
                            filter[header.value]
                              ? filter[header.value].length
                              : false
                          "
                          :value="
                            filter[header.value] &&
                            filter[header.value].length > 0
                          "
                          overlap
                          color="primary"
                          dot
                        >
                          <v-menu offset-y left :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn v-bind="attrs" v-on="on" x-small icon
                                ><v-icon color="grey" small
                                  >filter_alt</v-icon
                                ></v-btn
                              >
                            </template>
                            <v-list dense>
                              <v-list-item
                                v-for="(item, index) in headerOptions(
                                  header.value
                                )"
                                :key="index"
                              >
                                <v-list-item-action class="pr-0">
                                  <v-checkbox
                                    v-model="filter[header.value]"
                                    :value="item.value"
                                    hide-details
                                    dense
                                    @change="calculateDisplayData()"
                                  ></v-checkbox>
                                </v-list-item-action>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </v-badge>
                      </th>
                    </thead>
                    <tbody
                      style="
                        margin-top: 25px;
                        padding-top: 50px;
                        font-size: 11px;
                        max-height: 70vh;
                        min-height: 70vh;
                        overflow-y: auto;
                      "
                       :key="filterKey"
                    >
                      <tr
                        v-for="stockItem in filterDisplayData()"
                        :key="stockItem.id"
                        :style="{
                          'border-top': '0.4px solid rgb(95, 95, 95)',
                          width: '100%',
                          background: '',
                        }"
                        class="text-center"
                      >
                        <td
                          v-for="(header, index) in headers"
                          :key="index"
                          :style="{
                            'border-top': '0.4px solid rgb(95, 95, 95)',
                          }"
                        >
                          <div v-if="header.value == 'status'">
                            <v-chip
                              x-small
                              outlined
                              @click="addToFilter(stockItem.status, 'status')"
                              :color="getPalletStatusColor(stockItem.status)"
                            >
                              {{ stockItem.status }}
                            </v-chip>
                          </div>
                          <div v-else>
                            <v-chip
                              v-if="stockItem[header.value]"
                              small
                              class="primaryText--text"
                              outlined
                              @click="
                                addToFilter(
                                  stockItem[header.value],
                                  header.value
                                )
                              "
                              style="border: none; font-size: 11px; z-index: 1"
                            >
                              {{ stockItem[header.value] }}
                            </v-chip>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div
                    class="containerMessage"
                    v-if="!loading && displayData.length == 0"
                  >
                    <h3 style="color: grey">No Results Found</h3>
                  </div>
                </v-list>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <div v-else>
          <valhalla-loading height="75vh" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    filterType: {
      type: String,
      required: true,
    },
    locationCode: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    downloadingExport: false,
    filter: {},
    filterKey: 100,
    loading: false,
    headers: [
      {
        text: "Status",
        value: "status",
        allowSort: true,
        allowFilter: true,
        width: '100px'
      },
      {
        text: "Location",
        value: "locationCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Identifier",
        value: "sscc",
        allowSort: true,
        allowFilter: false,
      },
      {
        text: "Seq.",
        value: "sequenceNo",
        allowSort: true,
        allowFilter: false,
      },
      {
        text: "Location Age",
        value: "age",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Insp. Age",
        value: "inspectionAge",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "PUC",
        value: "puc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Orch.",
        value: "orchard",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Producer",
        value: "producerName",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "PHC",
        value: "phc",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Comm.",
        value: "commodityCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Var.",
        value: "varietyCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Pack Code",
        value: "packCode",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Mark",
        value: "mark",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Class",
        value: "grade",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Cartons",
        value: "cartonQuantity",
        allowSort: true,
        allowFilter: false,
      },
      {
        text: "Target Region",
        value: "targetRegion",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Target Country",
        value: "targetCountry",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Target Market",
        value: "targetMarket",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Order",
        value: "systemOrderNo",
        allowSort: true,
        allowFilter: true,
      },

      {
        text: "Allocation",
        value: "bookingContainerNo",
        allowSort: true,
        allowFilter: true,
      },
      {
        text: "Vessel",
        value: "vesselVoyage",
        allowSort: true,
        allowFilter: true,
      },

      {
        text: "Customer",
        value: "customerName",
        allowSort: true,
        allowFilter: true,
      },
    ],
    palletStatus: [
    { name: "In Stock", icon: "local_shipping", color: "orange" },
      { name: "Allocated", icon: "fact_check", color: "cyan" },
      { name: "In Transit", icon: "local_shipping", color: "teal" },
      { name: "Rejected", icon: "verified", color: "orange" },
      { name: "Stuffed", icon: "check_circle_outline", color: "lime" },
      { name: "Complete", icon: "check_circle_outline", color: "#4CAF50" },
      { name: "Cancelled", icon: "cancel", color: "red" },
      { name: "Instruction Sent", icon: "email", color: "indigo" },
      {name:"Removed", icon: "cancel", color: "red"},
      { name: "Confirmed", icon: "verified", color: "teal" },


    ],
    search: null,
    searchKey: 200,
    sortBy: null,
    sortDesc: false,
    stock: [],
  }),
  computed: {
  
  },
  created() {
    this.setFilter();
    this.getAllStock();
  },
  methods: {
    filterDisplayData() {
      let result = this.displayData;
      if (this.search) {
        result = result.filter(
          (x) =>
            x.sscc.toLowerCase().includes(this.search.toLowerCase()) ||
            (x.locationCode &&
              x.locationCode
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.bookingContainerNo &&
              x.bookingContainerNo
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.commodityCode &&
              x.commodityCode
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.varietyCode &&
              x.varietyCode
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.mark &&
              x.mark.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.grade &&
              x.grade.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.targetMarket &&
              x.targetMarket
                .toLowerCase()
                .includes(this.search.toLowerCase())) ||
            (x.phc &&
              x.phc.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.puc &&
              x.puc.toLowerCase().includes(this.search.toLowerCase())) ||
            (x.orchard &&
              x.orchard.toLowerCase().includes(this.search.toLowerCase()))
        );
      }
      return result;
    },
    addToFilter(value, header) {
      if (this.filter[header].includes(value)) {
        this.filter[header] = this.filter[header].filter((x) => x != value);
      } else {
        this.filter[header].push(value);
      }
      this.filterKey++;
      this.searchKey ++
      this.calculateDisplayData();
    },
    calculateDisplayData() {
      let result = [...this.stock];
      let appliedfilter = Object.keys(this.filter).filter(
        (x) => this.filter[x].length > 0
      );
      this.filteredValues = appliedfilter;
      for (let i = 0; i < appliedfilter.length; i++) {
        if (appliedfilter[i] == "grade") {
          result = result.filter(
            (x) =>
              this.filter[appliedfilter[i]].includes(x.formattedClass) ||
              this.filter[appliedfilter[i]].includes(x.grade)
          );
        } else {
          result = result.filter((x) =>
            this.filter[appliedfilter[i]].includes(x[appliedfilter[i]])
          );
        }
      }
      if (this.sortBy) {
        result = result.sort((a, b) => {
          if (this.sortDesc) {
            return a[this.sortBy] < b[this.sortBy] ? 1 : -1;
          } else {
            return a[this.sortBy] > b[this.sortBy] ? 1 : -1;
          }
        });
      }
      this.displayData = result;
      this.searchKey ++
      this.filterKey++;
    },
    changeSortBy(value) {
      if (this.sortBy == value) {
        if (this.sortDesc) {
          this.sortBy = null;
          this.sortDesc = false;
        } else {
          this.sortDesc = true;
        }
      } else {
        this.sortBy = value;
        this.sortDesc = false;
      }
      this.calculateDisplayData();
    },
    async downloadExport() {
      this.downloadingExport = true;
      let data = await this.$API.getStockExport({
        filterType: this.filterType,
        locationCode: this.locationCode
      });
      var hiddenElement = document.createElement("a");
      hiddenElement.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        data;
      hiddenElement.target = "_blank";
      hiddenElement.download = "Stock_" + new Date().getTime() + ".xlsx";
      hiddenElement.click();
      this.downloadingExport = false;
    },
    getPalletStatusColor(status){
      let find = this.palletStatus.find(x => x.name == status);
      return find ? find.color : 'grey';
    },
    headerOptions(value) {
      let result = [
        ...new Set(
          this.stock
            .filter((x) => x[value])
            .map((x) => ({ name: x[value], value: x[value] }))
        ),
      ];
      let uniqueValues = [...new Set(result.map((x) => x.value))];
      result = uniqueValues.map((x) => result.find((y) => y.value === x));
      return result;
    },
    async getAllStock() {
      this.loading = true;
      let data = await this.$API.getActiveStockOverview({
        filterType: this.filterType,
        locationCode: this.locationCode
      });
      this.stock = data;
      this.calculateDisplayData();
      this.loading = false;
    },
    resetFilters() {
      this.setFilter();
      this.calculateDisplayData();
    },
    setFilter() {
      let values = this.headers.map((x) => x.value);
      this.filter = {};
      values.forEach((x) => {
        this.filter[x] = [];
      });
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 70vh;
  margin: auto;
  /* outline: solid 1px black; */
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>